import React, { useEffect, useState, useRef } from "react";
import { arrayOf, shape } from "prop-types";
import moment from "moment";
import { produce } from "immer";
import { useClickOutside } from "@shared/hookHelpers";
import FieldLabel from "@shared/v2/FieldLabel";
import TextCell from "../../Transactions/components/shared/TextCell";
import PillButton from "../../Transactions/components/shared/PillButton";
import Dropdown from "@shared/v2/Dropdown";
import { CalendarDaysSolidV6 } from "@shared/v2/Icomoon";
import { defaultTask } from "../../Tasks/components/TaskForm";
import { TimeFrameDateSelector } from "../../TransactionDetailsPage/Header/ReferralHeader/TimeFrameDateSelector/TimeFrameDateSelector";

const DEFAULT_STATE = {
  ...defaultTask,
  name: 'Follow Up Call',
  type: 'Call',
  assigned_to_user_id: null,
  due_on: moment().format("YYYY-MM-DD"),
  priority: 3
};

const DATE_HASH = {
  Today: moment().format("YYYY-MM-DD"),
  Tomorrow: moment().add(1, 'days').format("YYYY-MM-DD"),
  '7 Days': moment().add(7, 'days').format("YYYY-MM-DD"),
  '30 Days': moment().add(30, 'days').format("YYYY-MM-DD"),
  '90 Days': moment().add(90, 'days').format("YYYY-MM-DD"),
}

const CustomPillButton = ({ onClick, isActive, children, className }) => (
  <PillButton
    onClick={onClick}
    className={`tw-border-1px tw-border-solid tw-border-gray-15 tw-text-gray-75 ${isActive ? "tw-text-white tw-bg-theme-button-primary" : "tw-bg-transparent"
      } ${className}`}
  >
    {children}
  </PillButton>
);

const AddTaskPane = ({ agents, initialUserUuid, initialAgentValue, setState }) => {
  const [task, setTask] = useState(DEFAULT_STATE);
  const [dateType, setDateType] = useState('Today');
  const [showDateSelector, setShowDateSelector] = useState(false);


  useEffect(() => {
    const DEFAULT_ST = {
      ...DEFAULT_STATE,
      assigned_to_user_id: initialUserUuid
    }
    setTask(DEFAULT_ST)
    setState(DEFAULT_ST)
    return () => {
      setTask(DEFAULT_ST);
      setState(null);
    }
  }, []);

  const handleChange = (key, value) => {
    setTask(prev => {
      const task = produce(prev, draft => {
        draft[key] = value;
      })

      setState(task);
      return task;
    })
  };

  const ref = useRef(null);
  useClickOutside(ref, () => {
    handleChange('due_on', moment().format("YYYY-MM-DD"));
    setShowDateSelector(false);
  });

  return (
    <div className="tw-p-16px tw-border-1px tw-border-solid tw-border-gray-10 tw-rounded-6px">
      <FieldLabel className="tw-mb-8px" label="Task" />
      <TextCell
        onChange={(txt) => {
          handleChange('name', txt.trim());
        }}
        editableDisplayClassName='tw-text-18d tw-text-gray-50'
        editableContainerClassName='tw-flex tw-items-center'
        value={task.name}
        iconProps={{ style: { fontSize: "12px" } }}
        isEditable
      />
      <div className="tw-flex tw-flex-col tw-gap-24px tw-mt-24px">
        <div className="tw-flex tw-justify-between">
          <div>
            <FieldLabel className="tw-mb-8px" label="Type" />
            <div className="tw-flex tw-gap-4px">
              <CustomPillButton
                onClick={() => handleChange('type', 'Call')}
                isActive={task.type === 'Call'}
                className="tw-w-[90px]"
              >
                Call
              </CustomPillButton>
              <CustomPillButton
                onClick={() => handleChange('type', 'To-Do')}
                isActive={task.type === 'To-Do'}
                className="tw-w-[90px]"
              >
                To-Do
              </CustomPillButton>
            </div>
          </div>
          <div className="tw-w-[286px]">
            <FieldLabel className="tw-mb-8px" label="Assign To" />
            <Dropdown options={agents} onChange={(v) => handleChange('assigned_to_user_id', v.value)} value={agents.find(agent => agent.value === task.assigned_to_user_id) || initialAgentValue} />
          </div>
        </div>
        <div>
          <FieldLabel className="tw-mb-8px" label="Date" isRequired />
          <div className="tw-flex tw-justify-between">
            {['Today', 'Tomorrow', '7 Days', '30 Days', '90 Days', 'Custom'].map((label) => (
              <CustomPillButton
                key={label}
                onClick={() => {
                  setDateType(label);
                  if (label === 'Custom') {
                    setShowDateSelector(true);
                    return;
                  }
                  handleChange('due_on', DATE_HASH[label]);
                }}
                isActive={dateType === label}
                className="tw-w-[90px]"
              >
                {label !== 'Custom' ? label : <><span>Custom</span><CalendarDaysSolidV6 size="m" className="tw-ml-4px" /></>}
              </CustomPillButton>
            ))}
          </div>
          <TimeFrameDateSelector
            containerClassName="tw-h-[0px]"
            subContainerClassName="tw-right-0 tw-bottom-[30px]"
            clearBtnClassName="tw-hidden"
            refOuter={ref}
            dateSelectorOnChangeHandlr={(dateObj) => {
              handleChange('due_on', moment(dateObj.date, "MM/DD/YYYY").format("YYYY-MM-DD"));
            }}
            minDate={moment().format('MM/DD/YYYY')}
            dateSelectorValue={moment(task.due_on, "YYYY-MM-DD").format("MM/DD/YYYY")}
            submitHandlr={() => setShowDateSelector(false)}
            show={showDateSelector} />
        </div>
        <div>
          <FieldLabel className="tw-mb-8px" label="Priority" isRequired />
          <div className="tw-flex tw-justify-between">
            {[1, 2, 3, 4, 5, 6, 7, 8, 9].map((priority) => (
              <CustomPillButton
                key={priority}
                onClick={() => handleChange('priority', priority)}
                isActive={task.priority === priority}
                className="tw-w-[58px]"
              >
                {priority}
              </CustomPillButton>
            ))}
          </div>
          <div className="tw-flex tw-justify-between tw-text-12d tw-text-gray-50">
            <span>Highest</span>
            <span>Lowest</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddTaskPane;

AddTaskPane.propTypes = {
  agents: arrayOf(shape({})),
};

AddTaskPane.defaultProps = {
  agents: [],
};